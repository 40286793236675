import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import PostPreviewTile from '../components/post-preview-tile'
import { graphql } from "gatsby"

class SharePage extends React.Component {
  render() {
    const { allStrapiArticle } = this.props.data

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet>
            <title>经验谈</title>
          </Helmet>
          <div className="columns is-multiline">
            {allStrapiArticle.edges.map(({ node }) => (
              <PostPreviewTile
                post={node}
                key={node.id}
              />
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export default SharePage


export const defaultShareQuery = graphql`
  query {
    allStrapiArticle(
      limit: 2000
      sort: { order: DESC, fields: [updatedAt] }
      filter: {type: {eq: "share"}}
    ) {
      edges {
        node {
          id: strapiId
          title
          originLink
          updatedAt(formatString: "YYYY-MM-DD")
          date
          excerpt
          coverUrl
          fields {
            coverImage
          }
          originUrl
          contributor {
            name
            userLink
          }
          type
        }
      }
    }
  }
`
